import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../services/auth";

import "./register.css";

export const Register = () => {
  const navigate = useIonRouter();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleRegistration = (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    auth.register(name, email, password).then(() => {
      setIsLoading(false);
      navigate.push("/app", "root", "replace");
    });
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div className="page-content">
          <IonText className="hero">مرحبًا 👋</IonText>
          <IonText className="hero hero-sm">قم بإنشاء حساب جديد الآن</IonText>

          <form className="ion-margin-top" onSubmit={handleRegistration}>
            <IonItem>
              <IonLabel position="floating">الاسم</IonLabel>
              <IonInput
                disabled={isLoading}
                value={name}
                onIonChange={(e) => setName(e.detail.value!)}
                enterkeyhint="next"
                placeholder="الاسم"
              />
            </IonItem>

            <IonItem>
              <IonLabel position="floating">البريد الإلكتروني</IonLabel>
              <IonInput
                disabled={isLoading}
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
                type="email"
                enterkeyhint="next"
                placeholder="البريد الإلكتروني"
              />
            </IonItem>

            <IonItem>
              <IonLabel position="floating">كلمة المرور</IonLabel>
              <IonInput
                disabled={isLoading}
                value={password}
                onIonChange={(e) => setPassword(e.detail.value!)}
                type="password"
                enterkeyhint="enter"
                placeholder="كلمة المرور"
              />
            </IonItem>

            <div className="ion-margin-top">
              <IonButton disabled={isLoading} type="submit" expand="block">
                تسجيل حساب جديد
              </IonButton>

              <div className="ion-margin-top">
                <IonText>
                  <span>أو </span>
                  <Link to="/">قم بتسجيل الدخول</Link>
                </IonText>
              </div>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};
