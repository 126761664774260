import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { QueryClientProvider, QueryClient } from "react-query";
import { Home } from "./pages/Home";
import { Hotel } from "./pages/Hotel";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

setupIonicReact({
  mode: "ios",
  backButtonDefaultHref: "/app",
  backButtonText: "",
});

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <IonApp>
      <QueryClientProvider client={queryClient}>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/app" exact component={Home} />
            <Route path="/hotels/:id" exact component={Hotel} />
          </IonRouterOutlet>
        </IonReactRouter>
      </QueryClientProvider>
    </IonApp>
  );
};

export default App;
