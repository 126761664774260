import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonButtons,
  IonSearchbar,
  IonText,
  IonList,
  IonItem,
  IonListHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
  IonNote,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import FeaturesList from "../../components/features";
import { useHotel } from "../../services/hotels";

import "./hotel.css";

export const Hotel = ({ match }: any) => {
  const { data: hotel } = useHotel(match?.params?.id);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [search, setSearch] = useState("");

  useIonViewDidEnter(() => {
    if (hotel?.rooms) {
      setFilteredRooms(hotel.rooms);
    } else {
      setFilteredRooms([]);
    }
  });

  const handleSearch = (e: any) => {
    const text = e.target?.value!.toLowerCase();

    if (text) {
      const newRooms = hotel.rooms.filter((item: any) => {
        return item.name.toLowerCase().indexOf(text) > -1;
      });

      setFilteredRooms(newRooms);
    } else {
      setFilteredRooms(hotel.rooms);
    }

    setSearch(text);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{hotel?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList lines="full">
          <IonListHeader>
            <IonSearchbar
              value={search}
              onIonChange={handleSearch}
              placeholder="ابحث في الغرف..."
            />
          </IonListHeader>
          {filteredRooms.length > 0 ? (
            filteredRooms.map((room: any) => (
              <React.Fragment key={room.id}>
                <IonListHeader color="primary">
                  <IonText>{room.name}</IonText>
                </IonListHeader>
                <IonItem>
                  <FeaturesList features={room.features} />
                </IonItem>
                {room.note && (
                  <IonItem>
                    <IonNote>{room.note}</IonNote>
                  </IonItem>
                )}
                {room.prices.map((price: any) => (
                  <IonItem key={price.id}>
                    <IonGrid>
                      <IonRow class="ion-align-items-center">
                        <IonCol>
                          <IonText className="text-bold">{price.type}</IonText>
                        </IonCol>
                        <IonCol>
                          <IonBadge>{price.available_at}</IonBadge>
                        </IonCol>
                        <IonCol>
                          <IonBadge>{price.unavailable_at}</IonBadge>
                        </IonCol>
                        <IonCol size="2">
                          <IonText color="secondary">
                            <h3 className="ion-no-margin">${price.price}</h3>
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                ))}
              </React.Fragment>
            ))
          ) : (
            <>
              <IonText color="danger">
                <h2 className="ion-text-center">لا يوجد بيانات!</h2>
              </IonText>
            </>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};
