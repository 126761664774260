import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../services/auth";

import "./login.css";

export const Login = () => {
  const navigate = useIonRouter();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useIonViewWillEnter(() => {
    if (auth.check()) {
      navigate.push("/app", "root", "replace");
    }

    setIsLoading(false);
  });

  const handleLogin = (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    auth.login(email, password).then(() => {
      setIsLoading(false);
      navigate.push("/app", "root", "replace");
    });
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div className="page-content">
          <IonText className="hero">مرحبًا 👋</IonText>
          <IonText className="hero hero-sm">أهلًا بك مجددًا</IonText>

          <form className="ion-margin-top" onSubmit={handleLogin}>
            <IonItem>
              <IonLabel position="floating">البريد الإلكتروني</IonLabel>
              <IonInput
                disabled={isLoading}
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
                type="email"
                enterkeyhint="next"
                placeholder="البريد الإلكتروني"
              />
            </IonItem>

            <IonItem>
              <IonLabel position="floating">كلمة المرور</IonLabel>
              <IonInput
                disabled={isLoading}
                value={password}
                onIonChange={(e) => setPassword(e.detail.value!)}
                type="password"
                enterkeyhint="enter"
                placeholder="كلمة المرور"
              />
            </IonItem>

            <div className="ion-margin-top">
              <IonButton disabled={isLoading} type="submit" expand="block">
                تسجيل الدخول
              </IonButton>

              <div className="ion-margin-top">
                <IonText>
                  <span>أو </span>
                  <Link to="/register">قم بتسجيل حساب جديد</Link>
                </IonText>
              </div>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};
