import { CapacitorCookies } from "@capacitor/core";
import api from "./api";

export const useAuth = () => {
  return {
    check() {
      return Boolean(this.getToken());
    },
    getToken() {
      const token = document.cookie.split("token=");
      return token[1];
    },
    login(email: string, password: string) {
      return api
        .post("/login", {
          email,
          password,
        })
        .then(({ data }) => {
          CapacitorCookies.setCookie({
            key: "token",
            value: data.token,
          });
        });
    },
    register(name: string, email: string, password: string) {
      return api
        .post("/register", {
          name,
          email,
          password,
        })
        .then(({ data }) => {
          CapacitorCookies.setCookie({
            key: "token",
            value: data.token,
          });
        });
    },
    async logout() {
      await CapacitorCookies.deleteCookie({
        key: "token",
      });
    },
  };
};
