import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import FeaturesList from "../features";

import "./region_group.css";

export default function RegionGroupList({ group }: any) {
  return group.map((page: any) => (
    <React.Fragment key={`hotels_page_${page.nextId}`}>
      {page.data.map((group: any) => (
        <React.Fragment key={`hotels_region_${group.region.id}`}>
          <IonText className="region">{group.region.name}</IonText>

          {group.hotels.map((hotel: any) => (
            <React.Fragment key={`hotel_${hotel.id}`}>
              <IonCard
                className="card"
                button
                routerLink={`/hotels/${hotel.id}`}
              >
                <IonGrid>
                  <IonRow>
                    <IonCol size="4">
                      <img
                        alt={hotel.name}
                        src={hotel.image}
                        className="card-image"
                      />
                    </IonCol>
                    <IonCol size="8">
                      <IonCardHeader className="ion-no-padding">
                        <IonCardTitle className="card-title">
                          {hotel.name}
                        </IonCardTitle>
                        <IonCardSubtitle>
                          {hotel.rooms_count} غرف متاحة
                        </IonCardSubtitle>
                      </IonCardHeader>

                      {hotel.features.length > 0 && (
                        <IonCardContent className="ion-no-padding ion-margin-top">
                          <FeaturesList
                            keyStartWith="hotel_features"
                            features={hotel.features}
                          />
                        </IonCardContent>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  ));
}
