import { IonChip, IonIcon, IonLabel } from "@ionic/react";
import { close } from "ionicons/icons";
import "./features.css";

export default function FeaturesList({
  features,
  keyStartWith,
  onSelect,
  selected,
}: any) {
  const handleOnClick = (feature: any) => {
    if (selected === feature) {
      onSelect(undefined);
      return;
    }

    onSelect(feature);
  };

  return (
    <div className="features-container">
      {features?.map((feature: any) => (
        <IonChip
          onClick={() => handleOnClick(feature.id)}
          key={`${keyStartWith ?? "features"}-${feature.id}`}
          color="secondary"
        >
          <IonLabel>{feature.name}</IonLabel>
          {selected === feature.id && <IonIcon icon={close} />}
        </IonChip>
      ))}
    </div>
  );
}
