import qs from "qs";
import { useInfiniteQuery, useQuery } from "react-query";
import api from "./api";

export const useHotels = (filters: any) => {
  return useInfiniteQuery(
    ["hotels", filters],
    async ({ pageParam = undefined, queryKey }) => {
      const params = qs.stringify({
        filter: {
          name: queryKey[1].name ?? undefined,
          features: queryKey[1].features ?? undefined,
        },
        page: pageParam,
      });

      const res = await api.get(`/hotels?${params.toString()}`);
      return res.data;
    },
    {
      getNextPageParam: (page) => {
        console.log(page.current_page, page.last_page);
        return page.current_page === page.last_page
          ? undefined
          : page.current_page + 1;
      },
    }
  );
};

export const useHotel = (id: any) => {
  return useQuery(["hotels", id], async ({ queryKey }) => {
    const res = await api.get(`/hotels/${queryKey[1]}`);
    return res.data.data;
  });
};
