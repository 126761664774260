import axios, { AxiosError } from "axios";

const api = axios.create({
  baseURL: "http://jit_travel.localhost/api",
});

api.interceptors.request.use((config: any) => {
  const token = document.cookie.split("token=");

  if (token[1]) {
    config.headers.Authorization = `Bearer ${token[1]}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      window.location.replace("/");
    }
    return Promise.reject({ ...error });
  }
);

export default api;
