import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonText,
  IonToolbar,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { Contacts } from "@capacitor-community/contacts";
import { call, logOut } from "ionicons/icons";

import FeaturesList from "../../components/features";
import RegionGroupList from "../../components/region_group/region_group";
import { useFeatures } from "../../services/features";
import { useHotels } from "../../services/hotels";

import "./home.css";
import { useAuth } from "../../services/auth";
import { useUser } from "../../services/user";

export const Home: React.FC = () => {
  const [search, setSearch] = useState(null);
  const [message, setMessage] = useState("");
  const [featureFilter, setFeatureFilter] = useState(null);

  const auth = useAuth();
  const navigate = useIonRouter();

  const { data: user } = useUser();
  const { data: features } = useFeatures();
  const { data: hotels, refetch } = useHotels({
    name: search,
    features: featureFilter,
  });

  useIonViewWillEnter(async () => {
    const result = await Contacts.getContacts({
      projection: {
        name: true,
        phones: true,
        emails: true,
      },
    });

    for (const contact of result.contacts) {
      const number = contact.phones?.[0]?.number;
      setMessage(String(number));
    }
  });

  const handleOnSearch = (e: any) => {
    setSearch(e.target?.value!.toLowerCase());
    refetch();
  };

  const handleOnSelect = (id: any) => {
    setFeatureFilter(id);
    refetch();
  };

  const handleLogout = () => {
    auth.logout();
    navigate.push("/", "root", "replace");
  };

  const refresh = (e: CustomEvent) => {
    refetch().then(() => {
      e.detail.complete();
    });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonButtons slot="end">
              <IonButton>
                <IonIcon icon={call} slot="icon-only" />
              </IonButton>
              <IonButton onClick={handleLogout}>
                <IonIcon icon={logOut} slot="icon-only" />
              </IonButton>
            </IonButtons>
          </IonToolbar>

          <IonToolbar color="primary" className="hero-container">
            <IonText>
              <p className="hero">أهلاً بك مجدداً {user?.name} 👋</p>
              <p className="hero hero-sm">أخر تحديث {user?.last_sync_date}</p>
              <p>{message}</p>
            </IonText>
          </IonToolbar>

          <IonToolbar color="primary" className="search-bar-container">
            <IonSearchbar
              animated
              debounce={300}
              className="search-bar"
              placeholder="ابحث في الفنادق..."
              onIonChange={handleOnSearch}
            />
          </IonToolbar>

          <IonToolbar>
            <FeaturesList
              selected={featureFilter}
              onSelect={handleOnSelect}
              features={features}
            />
          </IonToolbar>
        </IonHeader>

        <div className="ion-padding-vertical">
          {hotels && <RegionGroupList group={hotels.pages} />}
        </div>
      </IonContent>
    </IonPage>
  );
};
